<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-500px">
            <div>
                <p class="title">구분추가</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <div class="mb-10">
                    <input type="text" v-model="pop.name" class="w-385px h-30px">
                    <button @click="pop.doSubmit" class="btn-default dp-inblock h-30px ml-10">추가</button>
                </div>
                <div class="overhidden overscroll maxh-550px">
                    <table class="tbl-light-list">
                        <colgroup>
                            <col width="80">
                            <col width="*">
                            <col width="150">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>구분</th>
                                <th>수정/삭제</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in pop.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td class="txt-left">
                                    <span v-if="irow.is_edit==false">{{ irow.name }}</span>
                                    <input type="text" v-if="irow.is_edit==true" v-model.trim="irow.name">
                                </td>
                                <td>
                                    <span @click="irow.is_edit=true" v-if="irow.is_edit==false" class="btn-view dp-inblock">수정</span>
                                    <span @click="pop.doModify(irow)" v-if="irow.is_edit==true" class="btn-view dp-inblock">저장</span>
                                    <span @click="pop.doDelete(irow.idx)" class="btn-view dp-inblock ml-10">삭제</span>
                                </td>
                            </tr>
                            <tr v-if="pop.total==0">
                                <td colspan="3">등록된 구분이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import Swal from 'sweetalert2';

export default {
    name: 'ERDivisionAddPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            name : "",

            list : [], total : 0,

            doDelete : (idx) => {
                Swal.fire({
                    title : '구분삭제',
                    text  : '해당 구분값을 삭제하시겠습니까?',
                    showCancelButton: true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            idx  : idx
                        };

                        axios.post("/rest/businessmgr/delCtype", params).then((res) => {
                            if( res.data.err == 0 ){
                                pop.doSearch();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                })
            },

            doModify : (o) => {
                let params = {
                    idx  : o.idx,
                    name : o.name
                };

                axios.post("/rest/businessmgr/modCtype", params).then((res) => {
                    if( res.data.err == 0 ){
                        pop.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    name : pop.name
                };

                axios.post("/rest/businessmgr/addCtype", params).then((res) => {
                    if( res.data.err == 0 ){
                        pop.name = "";
                        pop.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {};
                axios.get("/rest/businessmgr/getCtypeList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list  = res.data.list;
                        pop.total = res.data.total;

                        for(let i=0; i<pop.list.length; i++){
                            let irow = pop.list[i];

                            irow.is_edit = false;

                            pop.list[i] = irow;
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted

            pop.doSearch();
        })
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
.btn-view {
    font-size:12px; margin-right:3px;
}
</style>