<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <ERDivisionAddPopup v-if="pop.isShowCategory" @close="pop.hideCategoryAdd"></ERDivisionAddPopup>
        <QAreaPopup v-if="pop.isShowQArea" @close="pop.isShowQArea=false"></QAreaPopup>
        <CarrotTitle title="E.R (Execution Report)">
            <button class="btn-info ml-230">info</button>
            <div class="btn-ibbox title-btnbox float-right">
                <!-- <button @click="pop.doPrint" class="btn-default">인쇄하기</button> -->
                <button @click="pop.showQArea" class="btn-default ml-20">Q영역 설명보기</button>
            </div>
        </CarrotTitle>

        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="progress-area">
                        <div class="legend-box">
                            <span class="dot complete"></span>
                            <span class="txt">완료</span>
                            <span class="dot Proceeding"></span>
                            <span class="txt">진행</span>
                            <span class="dot"></span>
                            <span class="txt">보류/중지/기타</span>
                        </div>
                        <div class="progress-box">
                            <span class="text">{{ mod.info.team_name?mod.info.team_name:mod.info.office_name }} {{ mod.info.ename }} ({{ mod.info.kname }} )</span>
                            <div class="progress">
                                <div class="bar w-100per">
                                    <span v-if="mod.info.cnt_etc>0" class="txt">{{ mod.info.cnt_etc }}</span>
                                </div>
                                <div class="bar Proceeding" :style="{ 'width':(mod.info.per_done+mod.info.per_proc)+'%' }">
                                    <span class="txt">{{ mod.info.cnt_proc }} </span>
                                </div>
                                <div class="bar complete" :style="{ 'width':mod.info.per_done+'%' }">
                                    <span class="txt">{{ mod.info.cnt_done }} </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="txt-center mg-0 mt-40 mb-20">
                        <!--<button class="btn btn-prev">prev</button>-->
                        <!-- <button class="day btn-cal">2019-07-12</button> -->
                        <carrot-date-picker v-model="mod.info.wdate" class="dp-inblock w-120px"></carrot-date-picker>
                        <!--<button class="btn btn-next">next</button>-->
                        <div class="dp-table w-100per">
                            <button @click="pop.showCategoryAdd" class="btn-default float-right">+ 구분추가</button>
                            <button @click="mod.addTask" class="btn-default float-right mr-20">+ TASK 추가</button>
                        </div>
                    </div>
                    
                    <table class="table-col table-move"><!-- 행 이동 가능시 : table-move 추가 -->
                        <colgroup>
                            <col width="30">
                            <col width="70">
                            <col width="120">
                            <col width="220">
                            <col width="110">
                            <col width="120">
                            <col width="120">
                            <col width="100">
                            <col width="220">
                        </colgroup>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Q영역</th>
                                <th>구분</th>
                                <th>상세</th>
                                <th>진척율</th>
                                <th>시작일</th>
                                <th>완료일</th>
                                <th>기간/변경차수</th>
                                <th>Pending Matter</th>
                            </tr>
                        </thead>
                            <draggable 
                                tag="tbody" 
                                :list="mod.info.contents" 
                                handle=".handle"
                                item-key="idx"
                                @start="mod.dragging = true"
                                @end="mod.dragging = false"
                            >
                                <template #item="{ element }">
                                    <tr :idx="element.idx" :class="{ 'warning':(element.edate<mod.today&&element.per_proc!='100') }">
                                        <td>
                                            <button class="btn-move handle">move</button>
                                        </td>
                                        <td>
                                            <select v-model="element.qtype" class="w-100per">
                                                <option value="Q1">Q1</option>
                                                <option value="Q2">Q2</option>
                                                <option value="Q3">Q3</option>
                                                <option value="Q4">Q4</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select v-model="element.ctype" class="w-100per">
                                                <option value="">-</option>
                                                <option v-for="(jrow, j) in mod.ctype_list" :key="j" :value="jrow.name">{{ jrow.name }}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <textarea v-model.trim="element.detail" class="w-100per mh-200px" placeholder="자세한 업무 내용을 작성하세요." maxlength="500"></textarea>
                                        </td>
                                        <td>
                                            <span v-if="element.per_proc=='100'">100%</span>
                                            <select v-else v-model="element.per_proc" class="w-60px">
                                                <option value="0">0</option>
                                                <option :value="(10*i)" v-for="i in 10" :key="i">{{ (10*i) }}</option>
                                                <option value="PAUSE">보류</option>
                                                <option value="STOP">중지</option>
                                                <option value="ETC">기타</option>
                                            </select>
                                            <span class="ml-10">%</span>
                                        </td>
                                        <td>
                                            <carrot-date-picker v-if="element.is_new=='Y'" v-model="element.sdate" class="dp-inblock w-110px" @change="mod.changeDate(element)"></carrot-date-picker>
                                            <template v-else>{{ element.sdate }}</template>
                                        </td>
                                        <td>
                                            <carrot-date-picker v-model="element.edate" class="dp-inblock w-110px" @change="mod.changeDate(element)"></carrot-date-picker>
                                        </td>
                                        <td>{{ element.period }} 일 / {{ element.cnt_edit }}회</td>
                                        <td>
                                            <textarea v-model="element.pendingmatter" class="w-100per mh-200px" maxlength="500"></textarea>
                                        </td>
                                    </tr>
                                </template>
                            </draggable>
                    </table>

                    <div class="mt-40 mb-20 dp-table w-100per">
                        <div class="block-title float-left mt-10">이슈사항</div>
                        <button @click="mod.addIssue" class="btn-default float-right">+ 이슈 추가</button>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="150">
                            <col width="1050">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>고객사</th>
                                <th>이슈내용</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in mod.info.issues" :key="i">
                                <td><carrot-company v-model="irow.idx_company" class="w-100per"></carrot-company></td>
                                <td>
                                    <input type="text" v-model="irow.contents" class="w-100per">
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="mod.doCancel" class="btn-default float-left mt-30">목록</button>
                    <button @click="mod.doSubmit" class="btn-default float-right mt-30">저장</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
// import Swal from 'sweetalert2'
import draggable from "vuedraggable";
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue';
import ERDivisionAddPopup from '@/components/popup/BusinessManagement/ERDivisionAddPopup.vue'
import QAreaPopup from '@/components/popup/BusinessManagement/QAreaPopup.vue'


export default {
    layout:"BusinessManagement",
    components: {
        CarrotDatePicker,
        ERDivisionAddPopup,
        QAreaPopup,
        CarrotCompany,
        draggable
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const pop = reactive({
            // Popup 노출 여부
            isShowCategory : false,
            isShowQArea    : false,

            showQArea : () => {
                pop.isShowQArea = true;
            },

            showCategoryAdd : () => {
                pop.isShowCategory = true;
            },
            hideCategoryAdd : () => {
                mod.getCtypeList();
                pop.isShowCategory = false;
            }
        });

        const mod = reactive({
            is_loaded  : false,
            ctype_list : [],

            today : "",

            idx : 0,
            wdate : "",
            info  : {},
            dragging : false,
            contents : [
                { qtype:"Q1", ctype:"", detail:"", per_proc:"0", sdate:"", edate:"", period:"", cnt_edit:0, pendingmatter:"" },
                { qtype:"Q1", ctype:"", detail:"", per_proc:"0", sdate:"", edate:"", period:"", cnt_edit:0, pendingmatter:"" },
                { qtype:"Q1", ctype:"", detail:"", per_proc:"0", sdate:"", edate:"", period:"", cnt_edit:0, pendingmatter:"" },
            ],
            issues   : [ 
                { idx_company:0, companyname:"", contents:"" },
            ],

            changeDate : async (el) => {
                if( el.sdate && el.edate ){
                    let sd = new Date(el.sdate);
                    let ed = new Date(el.edate);
                    let pe = ed.getTime() - sd.getTime();
                    if(pe<0){
                        el.edate=el.sdate;
                        toast.error("종료일은 시작일보다 빠를 수 없습니다");
                    }

                    let params = {
                        sdate : el.sdate,
                        edate : el.edate
                    };
                    await axios.get("/rest/external/calculateWorkdays", { params : params }).then((res) => {
                        if( res.data.err == 0 ){
                            const period = res.data.period;
                            for(let i=0; i<mod.info.contents.length; i++){
                                let irow = mod.info.contents[i];
                                if( irow.idx == el.idx ){
                                    mod.info.contents[i].period = period;
                                    return period;
                                }
                            }
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                            return 0;
                        }
                    });
                }
            },

            getCtypeList : () => {
                let params = {};
                axios.get("/rest/businessmgr/getCtypeList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.ctype_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            addTask : () => {
                mod.info.contents.push({ qtype:"Q1", ctype:"", detail:"", per_proc:"0", sdate:mod.today, edate:"", period:"", cnt_edit:0, pendingmatter:"", is_new:'Y', idx : mod.info.contents.length +1});
            },

            addIssue : () => {
                mod.info.issues.push({ idx_company:0, companyname:"", contents:"" });
            },

            doCancel : () => {
                router.go(-1);
            },

            doSubmit : () => {
                let params = {
                    idx   : mod.idx,
                    wdate : mod.info.wdate,
                    contents : mod.info.contents,
                    issues   : mod.info.issues,
                };

                for(let i=0; i<params.contents.length; i++){
                    let irow = params.contents[i];

                    if( !irow.ctype ){
                        toast.error((i+1) + "번째 TASK의 구분을 선택하세요.");
                        return;
                    }
                    if( !irow.detail ){
                        toast.error((i+1) + "번째 TASK의 상세를 입력하세요.");
                        return;
                    }
                    if( !irow.sdate || !irow.edate ){
                        toast.error((i+1) + "번째 TASK의 시작일/완료일을 입력하세요.");
                        return;
                    }
                }

                axios.post("/rest/businessmgr/modER", params).then((res) => {
                    if( res.data.err == 0 ){
                        router.push({
                            name : 'BusinessManagement-ERView-idx',
                            params : {
                                idx : res.data.idx
                            }
                        })
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = { 
                    idx : mod.idx
                };

                axios.get("/rest/businessmgr/getERInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;

                        for(let i=0; i<mod.info.contents.length; i++){
                            mod.info.contents[i].is_new = 'N';
                        }

                        mod.is_loaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            mod.idx = route.params.idx?route.params.idx:0;

            if ( !mod.idx ) {
                router.back(-1);
            }

            let nd = new Date();
            mod.today = nd.getFullYear()
                    + '-' + (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1))
                    + '-' + (nd.getDate()>9?nd.getDate():'0'+nd.getDate());

            mod.getCtypeList();
            mod.doSearch();
        });

        return {pop, mod};
    }
}
</script>

<style lang="scss" scoped>
.warning {
  background-color:rgba(201, 121, 121, 0.2);
}
.mh-200px {
  min-height: 200px !important;
}
</style>